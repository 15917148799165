import { cva, type VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef } from "react";

import { cn } from "~/utils/classnames";

export const labelStyles = cva("block font-medium group-aria-disabled:opacity-60", {
  variants: {
    inputSize: {
      xs: "px-3 text-xs",
      sm: "px-4 text-sm",
      default: "px-4 text-sm",
      lg: "px-5 text-base",
    },
  },
  defaultVariants: {
    inputSize: "default",
  },
});

interface LabelProps extends VariantProps<typeof labelStyles>, ComponentPropsWithoutRef<"label"> {
  required?: boolean;
}

export function Label({ inputSize, required, className, children, ...props }: LabelProps) {
  return (
    <label className={cn(labelStyles({ inputSize, className }))} {...props}>
      {children}
      {required ? <span>*</span> : null}
    </label>
  );
}
